<template>
  <div id="app">
    <v-app>
      <v-app-bar class="header-wrapper" dense flat app dark>
        <div class="d-flex align-center fill-height ml-2">
          <v-icon small>mdi-store-marker</v-icon>
          <div style="text-align: left" class="ml-2" v-if="restaurantInfo">
            <div class="text-body-2">{{ restaurantInfo.name }}</div>
          </div>
        </div>

        <v-spacer></v-spacer>
        <LangSwitch/>
      </v-app-bar>
      <v-main app>
        <router-view></router-view>
      </v-main>
      <v-bottom-navigation color="primary" grow dark :value="activeRoute" app height="64">
        <v-btn @click="goto('/index')" value="/index">
          <span class="text-capitalize">{{ $t('Home') }}</span>
          <v-icon class="mb-1">mdi-home</v-icon>
        </v-btn>
        <v-btn @click="goto('/menu')" value="/menu">
          <span class="text-capitalize">{{ $t('Menu') }}</span>
          <v-icon class="mb-1">mdi-food-fork-drink</v-icon>
        </v-btn>
        <v-btn @click="goto('/order')" value="/order">
          <span class="text-capitalize">{{ $t('Order') }}</span>
          <v-icon class="mb-1">mdi-list-box</v-icon>
        </v-btn>

        <v-btn @click="goto('/my')" value="/my">
          <span class="text-capitalize">{{ $t('My') }}</span>
          <v-icon class="mb-1">mdi-account</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <v-dialog persistent :value="showAnnouncement">
        <v-card v-if="restaurantInfo" class="pa-4 py-6 text-center">
          <div class="text-body-1 amber--text text--lighten-4 font-weight-bold">
            {{ restaurantInfo.buffetAnnouncementHead }}
          </div>
          <div class="text-body-2 mt-6">
            {{ restaurantInfo.buffetAnnouncementBody }}
          </div>
          <v-btn @click="closeShowAnnouncement" class="mt-6" color="grey lighten-4 black--text">{{$t('Close') }}</v-btn>
        </v-card>
      </v-dialog>
      <v-bottom-sheet persistent :value="showLoginPage">
        <login-page/>
      </v-bottom-sheet>
      <v-bottom-sheet persistent :value="showComingPage">
        <v-card class="pa-4 py-6" tile color="grey darken-3">
          <div class="text-h6">
            {{ $t('FeatureNotAvailable') }}
          </div>
          <div class="text-body-1 mt-4">
            {{ $t('StayTuned') }}!
          </div>
          <v-btn @click="closeComingPage" color="grey lighten-4 black--text mt-4">
            {{ $t('Close') }}
          </v-btn>
        </v-card>
      </v-bottom-sheet>
      <v-bottom-sheet fullscreen scrollable :value="showAGB">
        <v-card>
          <div class="pa-6 text-body-2 pb-16" style="white-space: pre-line;overflow-y: scroll">
            <div class="text-h6">{{ $t('GeneralTerms') }}</div>
            <div class="text-body-1 my-2 amber--text text--lighten-4">{{ $t('GeneralTermsScope') }}</div>
            {{ $t('GeneralTermsText') }}<br>
            <div class="text-body-1 my-2 amber--text text--lighten-4">{{ $t('ConclusionContract') }}</div>
            {{ $t('ConclusionContractText') }}<br>
            {{ $t('ConclusionContractText1') }}<br>
            <div class="text-body-1 my-2 amber--text text--lighten-4">{{ $t('PricesTermsPayment') }}</div>
            {{ $t('PricesTermsPaymentText') }}<br>
            {{ $t('PricesTermsPaymentText1') }}<br>
            {{ $t('PricesTermsPaymentText2') }}<br>
            <div class="text-body-1 my-2 amber--text text--lighten-4">{{ $t('DeliveryCollection') }}</div>
            {{ $t('DeliveryCollectionText') }}<br>
            {{ $t('DeliveryCollectionText1') }}<br>
            <div class="text-body-1 my-2 amber--text text--lighten-4">{{ $t('CancellationModificationOrders') }}</div>
            {{ $t('CancellationModificationOrdersText') }}<br>
            {{ $t('CancellationModificationOrdersText1') }}<br>
            <div class="text-body-1 my-2 amber--text text--lighten-4">{{ $t('WarrantyLiability') }}</div>
            {{ $t('WarrantyLiabilityText') }}<br>
            {{ $t('WarrantyLiabilityText2') }}<br>
            {{ $t('WarrantyLiabilityText3') }}<br>
            <div class="text-body-1 my-2 amber--text text--lighten-4"> {{ $t('DataProtection') }}</div>
            {{ $t('DataProtectionText') }}<br>
            {{ $t('DataProtectionText1') }}<br>
            {{ $t('DataProtectionText2') }}<br>
            <div class="text-body-1 my-2 amber--text text--lighten-4">{{ $t('SeverabilityClause') }}</div>
            {{$t('SeverabilityClauseText') }}<br>
            <div class="text-body-1 my-2 amber--text text--lighten-4">{{ $t('JurisdictionLaw') }}</div>
            {{ $t('JurisdictionLawText') }}<br>
            {{ $t('JurisdictionLawText1') }}<br>
            {{ $t('ChangeTermsConditions') }}<br>
            {{ $t('ChangeTermsConditionsText') }}<br>
            {{ $t('ChangeTermsConditionsText1') }}<br>

          </div>
          <v-btn @click="closeShowAGBPage"
                 style="position: fixed;left: 0;right: 0;bottom: 16px;width: min-content;margin: auto"
                 color="grey lighten-4 black--text">
            {{$t('Close') }}
          </v-btn>
        </v-card>
      </v-bottom-sheet>

    </v-app>
  </div>
</template>
<script>

import {getCurrentInfo, getRestaurantInfo, getTableName} from '@/dataLayer/service/api'
import LangSwitch from "@/components/LangSwitch";
import {mapState} from "vuex";
import LoginPage from "@/pages/LoginPage.vue";


export default {
  name: 'App',
  components: {
    LoginPage,
    LangSwitch,
  },
  data: function () {
    return {
      consumeTypeStatusId: 0,
      tableName: getTableName(),
      restaurantInfo: null,
    }
  },
  computed: {
    ...mapState(['showLoginPage', 'showComingPage', 'showAGB', "showAnnouncement"]),
    activeRoute() {
      return this.$route.path
    }
  },
  methods: {
    goto(path) {
      this.$router.push(path)
    },
    closeComingPage() {
      this.$store.commit('showComingPage', false)
    },
    closeShowAGBPage() {
      this.$store.commit('changeAGB', false)
    },
    closeShowAnnouncement() {
      this.$store.commit('showAnnouncement', false)
    }
  },
  mounted() {
    const heartBeat = async () => {
      try {
        this.restaurantInfo = await getRestaurantInfo()
        console.log(this.restaurantInfo)
        this.consumeTypeStatusId = (await getCurrentInfo()).order.consumeTypeStatusId
      } catch (e) {
        console.log(e)
      }

    }
    this.$router.push('/index')
    heartBeat()
    // heartBeat()
    // setInterval(heartBeat, 10000)
  }

}
</script>

<style>

.header-wrapper div .consumeStatus {
  margin: auto !important;
}

.header-wrapper .v-toolbar__content {
  flex-wrap: wrap;
}

.dishImage {
  border-radius: 16px;
  width: 120px;
  height: 80px;
  margin-left: 12px;
}

.dishLabel {
  flex-grow: 1;
}

.dishPrice {
  font-size: 0.9rem;
  color: #009de0;
}

.dishDesc {
  color: rgba(32, 33, 37, 0.64);
  position: relative;
  font-size: .75rem;
  line-height: 1.4;
  max-height: 4.2em;
  margin-bottom: 0.375rem;
  letter-spacing: -.15px;
  white-space: pre-line;
  overflow: hidden;
}


.dishName {
  padding: 0;
  font-size: 0.98rem;
  line-height: 1.4;
  max-height: 2.8em;
  font-weight: 600;
  margin-bottom: 0.375rem;
  white-space: pre-wrap;
  overflow: hidden;
  max-width: calc(100vw - 32px);
  text-overflow: ellipsis;
}


.categoryChipItem {
  max-width: 180px;
  padding: 12px 12px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  color: #707173;
  text-overflow: ellipsis;
  flex-shrink: 0;
  overflow: hidden;
}

.categoryTitle {
  font-size: 1.5rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

.categoryDesc {
  font-size: 0.9rem;
  color: rgba(32, 33, 37, 0.64);
  font-weight: 400;
  margin-top: 16px;
  white-space: pre-wrap;
}

.itemContainer {
  width: 100%;
  overflow: hidden;
}

.categoryContainer {
  margin: 1.5rem 16px
}

.infoButton {
  background: #ebf7fd !important;
  color: #009de0 !important;
  text-transform: capitalize;
}

.infoButton:hover {
  background: #ccebf9;
}

.listItem {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.5rem 0;
}

.contentPadding {
  padding: 16px;
}

</style>
<style lang="scss">
#app {
  font-family: 'Noto Sans SC', 'Noto Sans', "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: medium;
  user-select: none;
}

.decoText {
  font-family: cursive !important;
}

textarea {
  font-size: 10px;
}
</style>
