import firebase from 'firebase/compat'
import {collection, getDocs, query} from 'firebase/firestore'
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
} from 'firebase/auth'
import store from "@/store";

const firebaseConfig = {
  apiKey: 'AIzaSyATTtRB1PlOC-At0-ndKqap4RABwwCc6ew',
  authDomain: 'aaden-boss-test.firebaseapp.com',
  projectId: 'aaden-boss-test',
  storageBucket: 'aaden-boss-test.appspot.com',
  messagingSenderId: '604566804841',
  appId: '1:604566804841:web:a82cb7788517358ceb45f8',
  measurementId: 'G-CST41XTVMJ'
}
const websiteUrl = 'websiteUrls'
export const FireBaseApp = firebase.initializeApp(firebaseConfig)
export const FireBaseStore = firebase.firestore()

export const FireBaseAuth = firebase.auth()

FireBaseAuth.onAuthStateChanged(user => {
  console.log(user)
  if (user?.uid) {
    store.commit('changeLogIn', true)
    store.commit('changeUserInfo', user)
    store.commit('showLoginPage',false)
  } else {
    store.commit('changeLogIn', false)
  }

})

export async function logOut(){
  await FireBaseAuth.signOut()
}

export async function loginWithGoogle() {
  const provider = new GoogleAuthProvider()
  return await signInWithRedirect(FireBaseAuth, provider)
}

export async function loginWithApple() {
  const provider = new OAuthProvider('apple.com')
  return await signInWithRedirect(FireBaseAuth, provider)
}

export async function resetPasswordEmail(email) {
  return await sendPasswordResetEmail(FireBaseAuth, email)
}

export async function loginWithEmailAndPassword(email, password) {
  return await signInWithEmailAndPassword(FireBaseAuth, email, password)
}

export async function createUserWithEmail(email, password) {
  return await createUserWithEmailAndPassword(FireBaseAuth, email, password)
}

const collectionRef = collection(FireBaseStore, websiteUrl)

export async function showRecords() {
  const res = query(collectionRef)
  return (await getDocs(res)).docs.map(it => it.data())
}

export function getCurrentUserId() {
  return FireBaseAuth.currentUser?.uid
}

