<template>
  <div style="height: 100vh;" class="d-flex align-center flex-column justify-center">
    <lottie-animation v-cloak
                      style="max-width: 300px"
                      :autoPlay="true"
                      ref="animation"
                      :animationData="require('@/assets/wait.json')"></lottie-animation>
    <v-fade-transition>
      <div class="pa-4 text-center" v-if="show">
        <div class="text-h5">{{ $t('CheckoutSuccessful') }}！</div>
        <div class="text-body-1 mt-4">{{ $t('CheckoutPleaseWaitMoment') }}。{{ $t('YourTotalPrice') }}
          <span class="amber--text font-weight-black text--lighten-4">{{ totalPrice | priceDisplay }}</span>.
        </div>
        <v-btn @click="$emit('return')" color="primary black--text" class="mt-4">
          <v-icon left>mdi-check</v-icon>
          {{ $t('BackToMainPage') }}
        </v-btn>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'
import {getCurrentInfo} from '@/dataLayer/service/api' // import lottie-web-vue


export default {
  components: {LottieAnimation},
  name: "OrderComplete",
  data: function () {
    return {show: false, totalPrice: 0}
  },
  async mounted() {
    setTimeout(() => {
      this.$refs.animation.play()
      setTimeout(() => {
        this.show = true
      }, 1000)
    }, 1000)
    this.totalPrice = (await getCurrentInfo()).order.totalPrice
  }
}
</script>

<style scoped>

</style>
