import hillo from "hillo"

import i18n from '@/i18n'
import GlobalConfig, {Remember, TableName} from '@/dataLayer/repository/GlobalSettingManager'
import dayjs from "dayjs";


const dishListCache = []

export async function getDishList(lang) {
  if (dishListCache.length === 0) {
    (await getCategoryWithDishes(lang))
  }
  return dishListCache
}


let categoryCache = []

export async function openTableWithScanQR(tableId) {
  return (await hillo.post('Complex.php?op=openTableWithScanQR', {tableId}))
}

export function getTableName() {
  return TableName
}

export async function getCurrentInfo() {
  const tableId = (await getTableInfo()).id
  try {
    return (await hillo.silentGet('Tables.php?op=currentInfo', {id: tableId})).content

  } catch (e) {
    return {order: {consumeTypeStatusId: -1}}
  }
}

export async function dishesInTable() {
  const tableId = (await getTableInfo()).id
  try {
    const list = (await hillo.silentGet('Complex.php?op=dishesInTable',
      {tableId, lang: Remember.requestLocale})).content
    return list
  } catch (e) {
    return []
  }


}

export async function getTableInfo() {
  const tableName = getTableName()
  if (!tableName) {
    return null
  } else {
    return (await hillo.silentGet('Tables.php', {name: tableName})).content[0]
  }

}

export async function callServant() {
  const tableId = (await getTableInfo()).id
  return (await hillo.get('Complex.php?op=callServant', {lang: i18n.locale, tableId})).content
}

export async function requireCheckOut() {
  const tableId = (await getTableInfo()).id
  return (await hillo.get('Complex.php?op=requireCheckOut', {lang: i18n.locale, tableId})).content
}

export async function orderDish(order) {
  const id = (await getTableInfo()).id
  return await hillo.post('Complex.php?op=addDishesToTable', {
    params: JSON.stringify(order),
    tableId: id,
    printingKitchenBon: print ? 1 : 0
  })
}


export async function getRestaurantInfo() {
  return (await hillo.get('Restaurant.php?op=view')).content[0]
}

export async function getCategoryWithDishes() {
  if (categoryCache.length === 0) {
    categoryCache = (await hillo.get('Category.php?op=withConsumeType',
      {
        consumeTypeId: GlobalConfig.ConsumeTypeId,
        lang: Remember.requestLocale,
        chaos: dayjs().unix()
      })).content.filter(c => c.dishes.length > 0).sort((a, b) => {
      const rank = '10,9,8,11,13'.split(',')
      const idToRank = (id) => {
        return 10 - rank.indexOf(id.toString())
      }
      const [ra, rb] = [a.dishesCategoryTypeId, b.dishesCategoryTypeId].map(idToRank)
      return ra > rb ? -1 : 1
    })
      .map(c => {
        c.dishes = c.dishes
          .map(d => {
            d.dishName = d.dishName ? d.dishName.replaceAll('[/chili/]', '🌶️').replaceAll('[/gutou/]', '🦴') : ''
            d.count = 0
            d.modShow = false
            d.note = ''
            return d
          })
        dishListCache.push(...c.dishes)
        c.categoryName = c.langs[0].name
        c.desc = c.langs[0].desc
        return c
      })
    console.log(categoryCache)
  }

  return categoryCache
}

