<template>
  <div style="overflow-y: scroll">

    <div class="py-6" style="padding-bottom: 96px !important;">
      <div></div>
      <template v-if="step===0">
        <div class="px-4">
          <div class="text-h5">
            {{ $t('ChooseNumberPeople') }}
          </div>
          <div style="display: grid;grid-template-columns: repeat(4,minmax(0,1fr));grid-gap: 12px" class="mt-8">
            <v-card @click="selectedPersonCount=i"
                    :color="selectedPersonCount===i?'primary black--text':'grey darken-4'"
                    :key="i"
                    v-for="i in 16" elevation="0">
              <v-responsive :aspect-ratio="1" style="height: 100%">
                <div style="height: 100%" class="d-flex align-center justify-center text-body-1 font-weight-medium">
                  {{ i }}{{ $t('People') }}
                </div>
              </v-responsive>

            </v-card>
          </div>
          <template v-if="selectedPersonCount">
            <div class="text-body-1 mt-4">
              {{ $t('OpenTableHint') }}{{ selectedPersonCount }}{{ $t('OpenTableHint1') }}<span
              class="amber--text text--lighten-4 font-weight-black"> {{
                selectedPersonCount * 2.99 | priceDisplay
              }}</span>
            </div>

          </template>
          <div
            style="position: fixed;left: 5vw;right: 5vw;bottom: 20px;" class="d-flex">
            <v-card
              @click="$emit('close')"
              color="grey lighten-2 black--text"
              style="border-radius: 30px;"
              class="pa-4 mr-2 d-flex justify-center align-center"
            >
              <v-icon color="black">mdi-arrow-left</v-icon>
            </v-card>
            <v-card
              v-if="selectedPersonCount"
              @click="step++"
              color="primary black--text"
              style="border-radius: 30px;"
              class="pa-4 d-flex flex-grow-1 justify-center align-center"
            >
              <div class="ml-4">{{ $t('NextStep') }}</div>
              <v-icon right size="18" color="black">mdi-arrow-right</v-icon>
            </v-card>
          </div>
        </div>


      </template>
      <template v-if="step===1">
        <div class="text-h6 px-4">
          {{ $t('ChooseType') }}
        </div>
        <div class="mt-4 px-4">
          <v-card @click="selectType(t.name)" class="pa-4 mb-2" v-for="t in type" :key="t.name"
                  :color="selectedTypeName===t.name?'grey darken-3':''"
                  elevation="0">
            <div class="d-flex align-center justify-center">
              <div class="mr-4">

                <div class="text-body-1">
                  {{ t.name }}
                </div>
                <div class="text-body-2 text--secondary">
                  {{ t.desc }}
                </div>
                <div style="display: grid;grid-auto-columns: min-content;grid-auto-flow: column;grid-gap: 4px">
                  <v-card
                    v-if="t.recommended"
                    color="primary black--text"
                    class="mt-1 px-1 text-caption"
                    style="width: min-content;white-space: nowrap;">
                    {{ $t('RecommendedShop') }}
                  </v-card>
                  <v-card
                    color="grey lighten-2 black--text"
                    class="mt-1 px-1 text-caption"
                    style="width: min-content;white-space: nowrap;">
                    {{ $t('OnRequest') }} {{ t.maxFlavor }} {{ $t('TasteGood') }}
                  </v-card>
                </div>

              </div>
              <v-spacer></v-spacer>
              <div class="text-body-1 font-weight-bold flex-shrink-0 amber--text text--lighten-4">
                {{ t.price | priceDisplay }}
              </div>
            </div>
          </v-card>
        </div>
        <v-card
          @click="step--"
          color="grey lighten-2 black--text"
          style="border-radius: 36px;position: fixed;left:5vw;right: 5vw;bottom: 16px"
          class="pa-4 d-flex justify-center align-center"
        >
          <v-icon left size="18" color="black">mdi-arrow-left</v-icon>
          <div class="mr-4">{{  $t('Back')  }}</div>

        </v-card>
      </template>
      <template v-if="step===2">
        <div class="text-h6 px-4">
          {{ $t('CustomizeYourBase') }}
        </div>
        <div style="display: grid;grid-auto-flow: column;grid-gap: 12px;grid-auto-columns:min-content"
             class="mt-4 pl-4">
          <v-card @click="selectedTypeName=t.name" class="pa-4" v-for="t in type" :key="t.name"
                  :color="selectedTypeName===t.name?'grey darken-3':''"
                  elevation="0">
            <div style="height: 100%;white-space: nowrap" class="d-flex align-center justify-center text-body-2">
              {{ t.name }}
            </div>
          </v-card>
        </div>
        <div class="px-4">
          <template v-if="!selectedType.smallPot">
            <div class="my-10 mx-12 "
                 style="padding: 12%;
                 background: url('img/background/6.png') center / contain no-repeat">
              <template v-if="maxFlavors===1">
                <div
                  :style="selectedImage[0]?{
                  backgroundImage:'url(img/hotpot/'+selectedImage[0]+')'
                }:{
                  background:'rgba(255,255,255,0.15)'
                }"
                  style="width: 100%;background: left / cover;border-radius: 100% 100% 100% 100%">
                  <v-responsive :aspect-ratio="1"></v-responsive>
                </div>
              </template>
              <template v-if="maxFlavors===2">
                <div style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));">
                  <div
                    :style="selectedImage[0]?{
                  backgroundImage:'url(img/hotpot/'+selectedImage[0]+')'
                }:{
                  background:'rgba(255,255,255,0.15)'
                }"
                    style="width: 100%;background: left / cover;border-radius: 100% 0 0 100%/50%">
                    <v-responsive :aspect-ratio="0.5"></v-responsive>
                  </div>
                  <div
                    :style="selectedImage[1]?{
                  backgroundImage:'url(img/hotpot/'+selectedImage[1]+')'
                }:{
                  background:'rgba(255,255,255,0.2)'
                }"
                    style="width: 100%;background: right / cover;border-radius: 0 100% 100% 0/50%">
                    <v-responsive :aspect-ratio="0.5"></v-responsive>
                  </div>
                </div>

              </template>
              <template v-if="maxFlavors===3">
                <div style="border-radius: 50%;width:100%;position: relative;overflow: hidden">
                  <v-responsive :aspect-ratio="1">
                    <div

                      style="width: 58%;height: 58%;position: absolute;left: 50%;top: 50%;
                    transform-origin: 0% 0%;transform: rotate(0deg) skewX( -30deg);overflow: hidden">
                      <div :style="selectedImage[2]?{
                  backgroundImage:'url(img/hotpot/'+selectedImage[2]+')'
                }:{
                  background:'rgba(255,255,255,0.1)'
                }"
                           style="transform:translate(-50%,-50%) skewX(30deg) ;width: 200%;height: 200%;background: bottom / cover">
                      </div>

                    </div>
                    <div
                      style="width: 58%;height: 58%;position: absolute;left: 50%;top: 50%;
                    transform-origin: 0% 0%;transform: rotate(120deg) skewX( -30deg);overflow: hidden">
                      <div :style="selectedImage[0]?{
                  backgroundImage:'url(img/hotpot/'+selectedImage[0]+')'
                }:{
                  background:'rgba(255,255,255,0.12)'
                }"
                           style="transform:translate(-50%,-50%) skewX(30deg)  rotate(-120deg);width: 200%;height: 200%;background: bottom / cover">
                      </div>
                    </div>
                    <div

                      style="width: 58%;height: 58%;position: absolute;left: 50%;top: 50%;
                    transform-origin: 0% 0%;transform: rotate(240deg) skewX( -30deg);overflow: hidden">
                      <div :style="selectedImage[1]?{
                  backgroundImage:'url(img/hotpot/'+selectedImage[1]+')'
                }:{
                  background:'rgba(255,255,255,0.15)'
                }"
                           style="transform:translate(-50%,-50%) skewX(30deg) rotate(-240deg);width: 200%;height: 200%;background: bottom / cover">
                      </div>

                    </div>
                  </v-responsive>

                </div>

              </template>


            </div>

            <div class="d-flex align-center">
              <div class="text-h6">
                {{ selectedType.name }}
              </div>
              <v-spacer></v-spacer>
              <div class="text-h6">{{ selectedType.price| priceDisplay }}</div>
            </div>
            <div class="text-body-2  mt-2 text--secondary">
              {{ selectedType.desc }}
            </div>
            <div class="text-body-2 mt-4">
              {{ $t('YouCanChoose') }}{{ selectedType.maxFlavor }}{{ $t('TasteGood') }}
            </div>

          </template>
          <template v-else>
            <div class="d-flex align-center">
              <div>
                <div class="text-body-1 mt-4">
                  {{ $t('NumberGuests') }}: {{ selectedPersonCount }}人
                </div>
                <div class="text-body-2 text--secondary">
                  {{ $t('YouChooseBaseDinner') }}
                </div>
              </div>
              <v-spacer></v-spacer>
              <div class="text-h6 font-weight-bold">
                {{ selectedFlavors.length }}<span class="text-body-1">/{{ selectedPersonCount }}</span>
              </div>
            </div>

            <div class="mt-4">
              <v-card
                v-for="f in flavors"
                class="mb-2"
                :key="f.name"
              >

                <div class="d-flex pa-2 align-center">
                  <v-avatar>
                    <v-img :src="'img/hotpot/'+f.image"/>
                  </v-avatar>
                  <div class="ml-4">
                    <div class="text-body-1">
                      {{ f.name }}
                    </div>
                    <div class="text-body-2 text--secondary">
                      {{ 8.8 | priceDisplay }}
                    </div>
                  </div>
                  <v-spacer></v-spacer>
                  <div class=" d-flex align-center grey darken-3 mr-1" style="border-radius: 24px;">
                    <v-card color="grey darken-2" class="pa-3" style="border-radius: 24px" elevation="0"
                            @click="removeOne(f.name)">
                      <v-icon>mdi-minus</v-icon>
                    </v-card>
                    <div class="pa-2 mx-1">
                      {{ selectedFlavors.filter(it => it === f.name).length }}
                    </div>
                    <v-card color="grey darken-1" class="pa-3" style="border-radius: 24px"
                            elevation="0"
                            @click="selectedFlavors.push(f.name)">
                      <v-icon>mdi-plus</v-icon>
                    </v-card>
                  </div>

                </div>

              </v-card>
            </div>

          </template>
        </div>
        <div v-if="!selectedType.smallPot" class="mt-2 pl-4"
             style="display:grid;grid-auto-flow: column;grid-auto-columns: 96px;grid-gap: 12px;overflow-x: scroll">
          <v-card :color="selectedFlavors.includes(f.name)?'grey darken-3':''" @click="selectFlavor(f.name)"
                  v-for="f in flavors" :key="f.name">
            <v-responsive :aspect-ratio="2">
              <div style="height: 100%" class="d-flex pa-2 flex-column align-center justify-center">
                <v-avatar class="mt-2">
                  <v-img :src="'img/hotpot/'+f.image"/>
                </v-avatar>
                <div class="text-body-2 mt-4">
                  {{ f.name }}
                </div>
                <div class="text-caption text--secondary">
                  + {{ f.price | priceDisplay }}
                </div>
              </div>
            </v-responsive>
          </v-card>
        </div>
        <div v-if="step>0"
             style="position: fixed;left: 5vw;right: 5vw;bottom: 20px;" class="d-flex">
          <v-card
            @click="step--"
            color="grey lighten-2 black--text"
            style="border-radius: 30px;"
            class="pa-4 mr-2 d-flex justify-center align-center"
          >
            <v-icon color="black">mdi-arrow-left</v-icon>
          </v-card>
          <v-card
            :disabled="selectedFlavors.length!==maxFlavors&&selectedFlavors.length!==selectedPersonCount"
            @click="$emit('confirm')"
            color="primary black--text"
            style="border-radius: 30px;"
            class="pa-4 d-flex flex-grow-1 justify-center align-center"
          >
            <div class="ml-4">{{ $t('Sure') }}?</div>
            <v-icon right size="18" color="black">mdi-check</v-icon>
          </v-card>
        </div>



      </template>

    </div>


  </div>
</template>

<script>

import i18n from "@/i18n";

const flavors = [
  {name: i18n.t('SpicyButter'), image: '1.png', price: 0},
  {name: i18n.t('HealthMushrooms'), image: '2.png', price: 0},
  {name: i18n.t('SourTomatoes'), image: '3.png', price: 0},
  {name: i18n.t('HoofFlower'), image: '4.png', price: 4.5},
  {name: i18n.t('ClearSoup'), image: '5.png', price: 0},
]
const type = [{
  name: i18n.t('SingleFlavorKettle'),
  desc: i18n.t('SingleFlavorKettleText'),
  maxFlavor: 1,
  recommended: true,
  price: 9.8
}, {
  name: i18n.t('SingleFlavorKettle1'),
  desc: i18n.t('SingleFlavorKettleText1'),
  maxFlavor: 2,
  price: 18.8
},
  {
    name: i18n.t('SingleFlavorKettle2'),
    maxFlavor: 3,
    desc: i18n.t('SingleFlavorKettleText2'),
    price: 19.8
  },
  {
    name: i18n.t('SingleFlavorKettle3'),
    maxFlavor: 1,
    desc: i18n.t('SingleFlavorKettleText3'),
    price: 8.8,
    smallPot: true,
  }]
export default {
  name: "HotpotOpenTablePage",
  data: function () {
    return {
      selectedPersonCount: null,
      step: 0,
      selectedTypeName: i18n.t('SingleFlavorKettle3'),
      type,
      flavors,
      selectedFlavors: [],
    };
  },
  watch: {
    selectedTypeName() {
      this.selectedFlavors = []
    }
  },
  computed: {
    maxFlavors() {
      return this.selectedType.maxFlavor
    },
    selectedImage() {
      return flavors.filter(it => this.selectedFlavors.includes(it.name)).map(it => it.image)
    },
    selectedType() {
      return type.find(it => it.name === this.selectedTypeName)
    }
  },
  methods: {
    removeOne(name) {
      const index = this.selectedFlavors.findIndex(it => it === name)
      if (index !== -1) {
        this.selectedFlavors.splice(index, 1)
      }

    },
    selectType(name) {
      this.selectedTypeName = name
      this.step++
    },
    selectFlavor(name) {
      if (this.selectedFlavors.includes(name)) {
        this.selectedFlavors = this.selectedFlavors.filter(it => it !== name)
      } else {
        if (this.selectedFlavors.length >= this.maxFlavors) {
          this.selectedFlavors.shift()
        }
        this.selectedFlavors.push(name)
      }
      this.selectedFlavors = [...this.selectedFlavors]
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}

</style>
