<template>
  <div v-cloak>
    <v-card class="text-center pa-6 px-6"
            width="100%" max-width="400px"
            style="position: relative"
            color="black">
      <v-card v-if="okToLogin" @click="close" style="position: absolute;right: 16px;top: 16px;">
        <v-icon>mdi-close</v-icon>
      </v-card>
      <template v-if="!passwordForget">
        <div class="text-body-1 font-weight-bold" :class="okToLogin?'':'mt-12'">
          <!--          {{ okToLogin ? '请登录到您的账号' : '正在检查账号设置' }}-->
          {{ okToLogin ? $t('LoginRegister') : $t('CheckAccountSettings') }}
        </div>
        <div class="text-body-2 text--secondary mt-1">
          Aaden POS v {{ version }}
        </div>
        <div style="min-height: 196px" class="mt-6">
          <div v-if="!okToLogin" style="display: flex;justify-content: center">
            <v-progress-circular class="mt-4" indeterminate/>
          </div>
          <div v-else>
            <v-form ref="form" v-model="isFormValid" lazy-validation>
              <v-text-field
                v-model="email"
                :rules="[rules.required]"
                :validate-on-blur="false"
                :error="error"
                :label="$t('Email')"
                :name="$t('Email')"
                outlined
                @keyup.enter="submit"
                @change="resetErrors"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="showPassword ? 'text' : 'password'"
                :error="error"
                :error-messages="errorMessages"
                :label="$t('Password')"
                :name="$t('Password')"
                outlined
                @change="resetErrors"
                @keyup.enter="submit"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <div class="d-flex align-center">
                <v-checkbox :rules="[rules.required]" dense v-model="confirmed"/>
                <div class="text-caption text-left">{{ $t('MarkAgb') }}
                  <span @click="$store.commit('changeAGB',true)" class="text-decoration-underline">AGB和DatenSchutz条款</span>
                  。
                </div>
              </div>


              <v-btn
                :loading="isLoading"
                :disabled="isSignInDisabled"
                block
                x-large
                color="primary black--text"
                @click="submit"
              >{{ $t('LoginRegister') }}
              </v-btn>


              <div class="caption font-weight-bold text-uppercase my-3">{{ $t('OrUseThisWay') }}</div>

              <!--           external providers list -->
              <v-btn
                v-for="provider in providers"
                :key="provider.id"
                :loading="provider.isLoading"
                :disabled="isSignInDisabled"
                :color="provider.color"
                class="mb-2 black--text"
                block
                elevation="1"
                @click="signInProvider(provider.id)"
              >
                <v-icon small left>mdi-{{ provider.id }}</v-icon>
                {{ provider.label }}
              </v-btn>
            </v-form>
            <div v-if="errorProvider" class="error--text">{{ errorProviderMessages }}</div>
            <div class="mt-5">
              <a @click="passwordForget=true">
                {{ $t('forget_password') }}?
              </a>
            </div>
          </div>

        </div>

      </template>
      <template v-else>
        <v-card-title>{{ $t('find_password') }}</v-card-title>
        <v-card-text style="min-height: 196px">
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-text-field
              v-model="email"
              :rules="[rules.required]"
              :validate-on-blur="false"
              :error="error"
              :label="$t('input_email')"
              name="email"
              outlined
              @keyup.enter="submit"
              @change="resetErrors"
            ></v-text-field>
            <v-btn
              :loading="isLoading"
              :disabled="isSignInDisabled"
              block
              x-large
              color="primary black--text"
              @click="sendResetPasswordEmail"
            >{{ $t('input_email') }}
            </v-btn>
          </v-form>
        </v-card-text>
      </template>
    </v-card>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {
  createUserWithEmail,
  loginWithApple,
  loginWithEmailAndPassword,
  loginWithGoogle,
  resetPasswordEmail
} from "@/plugins/fire-base";

export default {
  name: 'LoginPage',
  computed: {
    ...mapState(['loginStateReady'])
  },
  data() {
    return {
      version: require('../../package.json').version,
      passwordForget: false,
      okToLogin: false,
      // sign in buttons
      isLoading: false,
      isSignInDisabled: false,
      confirmed: false,
      // form
      isFormValid: true,
      email: '',
      password: '',

      // form error
      error: false,
      errorMessages: '',

      errorProvider: false,
      errorProviderMessages: '',

      // show password field
      showPassword: false,

      providers: [{
        id: 'google',
        label: 'Google',
        isLoading: false,
        color: 'blue lighten-4'
      }, {
        id: 'apple',
        label: 'Apple',
        isLoading: false,
        color: 'green lighten-4'
      }],

      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || this.$t('Required')
      }
    }
  },
  watch: {
    loginStateReady(val) {
      console.log(val, 'state')
      if (val) {
        this.showLogin()
      }
    }
  },
  methods: {
    showLogin() {
      this.okToLogin = true
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        this.isSignInDisabled = true
        await this.signIn(this.email, this.password)
        this.isLoading = false
        this.isSignInDisabled = false
      }
    },
    async sendResetPasswordEmail() {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        this.isSignInDisabled = true
        await resetPasswordEmail(this.email)
        this.isLoading = false
        this.isSignInDisabled = false
        this.passwordForget = false
        this.errorMessages = "密码已经重设成功，请去邮箱里重置密码后登陆"
        this.password = ''
      }
    },
    async signIn(email, password) {
      try {
        await loginWithEmailAndPassword(email, password)
      } catch (e) {
        if (e.code === 'auth/user-not-found') {
          const res = await createUserWithEmail(email, password)
          console.log(res)
        } else {
          console.log(e.code, 'login')
          this.error = true
          this.errorMessages = this.$t('pls_check_account_and_pass') + ' ' + e.message
        }
      }
    },
    async signInProvider(id) {
      try {
        if (id === 'google') {
          await loginWithGoogle()
        } else {
          await loginWithApple()
        }

      } catch (e) {
        console.log(e, 'google')
        this.errorProvider = true
        this.errorProviderMessages = e.message
      }
    },
    resetErrors() {
      this.error = false
      this.errorMessages = ''
      this.errorProvider = false
      this.errorProviderMessages = ''
    },
    close() {
      this.$store.commit('showLoginPage', false)
    }
  },
  mounted() {
    setTimeout(() => this.showLogin(), 2000)
  }
}
</script>

<style scoped>

</style>
