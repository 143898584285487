import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '@/pages/MainPage'
import LoadingPage from "@/pages/LoadingPage"
import ErrorPage from '@/pages/ErrorPage'
import OrderComplete from '@/pages/OrderComplete'
import OrderPage from "@/pages/OrderPage.vue";
import MyPage from "@/pages/MyPage.vue";
import IndexPage from "@/pages/IndexPage.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'loading',
    component: LoadingPage
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage
  },
  {
    path: '/menu',
    name: 'Menu',
    component: MainPage
  },
  {
    path: '/order',
    name: 'order',
    component: OrderPage
  },
  {
    path: '/my',
    name: 'My',
    component: MyPage
  },
  {
    path: '/index',
    name: 'index',
    component: IndexPage
  },
  {
    path: '/end',
    name: 'End',
    component: OrderComplete
  }
]

const router = new VueRouter({
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes
})

export default router
