<template>
  <div>
    <div @click="$router.push('/menu')">
      <v-responsive :aspect-ratio="4/3">
        <v-carousel
          :show-arrows="false"
          cycle
          delimiter-icon="mdi-circle-small"
          hide-delimiter-background
          height="100%">
          <v-carousel-item
            v-for="(image) in imageList"
            :key="image.id"
          >
            <v-img :src="GlobalConfig.imageRoot+image.image" :aspect-ratio="4/3"></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-responsive>


    </div>
    <div class="mt-n4 mx-6">
      <v-card v-if="!tableUsing" flat class="pa-2" style="border-radius: 12px;display: grid;
      grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 24px">
        <div
          @click="openTable"
          class="d-flex pa-4 flex-column justify-center align-center"
        >
          <div class="text-h6 font-weight-black amber--text text--lighten-4">
            {{ $t('ScanCodeDineIn') }}

          </div>
          <div class="text-body-2 text--secondary">
            {{ $t('DeliciousFoodGlance') }}
          </div>
          <v-img contain width="64" src="img/homePage/restaurant.svg" class="mt-3"/>
        </div>
        <div
          @click="openTable"
          class="d-flex pa-4 flex-column justify-center align-center"
        >
          <div class="text-h6 font-weight-black  amber--text text--lighten-4">
            {{ $t('PickAndTakeAway') }}
          </div>
          <div class="text-body-2 text--secondary">
           {{ $t('AdditionalDiscount') }}
          </div>
          <v-img contain width="64" src="img/homePage/shopping-bag.svg" class="mt-3"/>
        </div>
      </v-card>
      <v-card v-else flat class="pa-4 py-6 d-flex" style="border-radius: 12px">
        <v-avatar size="36">
          <v-img src="img/homePage/restaurant.svg"></v-img>
        </v-avatar>
        <div class="ml-4">
          <div class="text-body-1 font-weight-black">
            {{ $t('CurrentTableNumber') }}{{ currentOrderInfo.name }}
          </div>
          <div
            class="text-caption text--secondary"
          >
            {{ $t('ContinueEnjoyFood') }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn @click="$router.push('/menu')" color="primary black--text">{{ $t('ContinueOrder') }}</v-btn>
      </v-card>
      <v-card v-if="isLogin" flat class="mt-4 pa-4 px-4 d-flex align-center" style="border-radius: 12px">
        <v-avatar size="36">
          <v-img src="img/homePage/woman.svg"></v-img>
        </v-avatar>
        <div class="ml-4 mr-2">
          <div class="text-body-1 font-weight-black">
            {{ $t('MyNotes') }} {{ 0 }}
          </div>
          <div
            class="text-caption text--secondary"
          >
            {{ $t('RedeemableCouponsLimitedMerchandise') }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <div @click="showUserCode=true" class="mr-4 d-flex flex-column align-center justify-center">
          <v-icon size="36">mdi-qrcode</v-icon>
        </div>
      </v-card>
      <v-card v-else flat class="mt-6 pa-4 px-4 d-flex align-center" style="border-radius: 12px">
        <v-avatar size="36">
          <v-img src="img/homePage/woman.svg"></v-img>
        </v-avatar>
        <div class="ml-4 mr-1">
          <div class="text-body-2 font-weight-black">
            {{ $t('WelcomeLoginBecomeMember') }}
          </div>
          <div
            class="text-caption text--secondary"
          >
            {{ $t('EnjoyPointsAndBenefits') }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn small @click="startLogin" color="primary black--text">{{ $t('SignUp') }}</v-btn>
      </v-card>
      <div class="mt-6" style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 12px">
        <v-card @click="displayComingPage" flat class="pa-4 d-flex align-center" style="border-radius: 12px">
          <v-img class="flex-grow-0" src="img/homePage/voucher.svg" width="36"></v-img>
          <div class="text-body-1 ml-3 text-capitalize">{{ $t('Coupon') }}</div>
        </v-card>
        <v-card @click="displayComingPage" flat class="pa-4 d-flex align-center" style="border-radius: 12px">
          <v-img class="flex-grow-0" src="img/homePage/profits.svg" width="36"></v-img>
          <div class="text-body-1 ml-3 text-capitalize">{{  $t('RechargeCredit')  }}</div>
        </v-card>
      </div>
    </div>

    <v-bottom-sheet v-model="showUserCode">
      <user-code-page/>
    </v-bottom-sheet>

    <v-bottom-sheet scrollable fullscreen v-model="showHotPotOpenTablePage" persistent>
      <v-card tile color="black">
        <hotpot-open-table-page @close="showHotPotOpenTablePage=false" @confirm="openTable"/>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import HotpotOpenTablePage from "@/pages/HotpotOpenTablePage.vue";
import {getTableInfo, openTableWithScanQR} from "@/dataLayer/service/api";
import {mapState} from "vuex";
import UserCodePage from "@/pages/UserCodePage.vue";
import {getBannerImageList} from "@/dataLayer/repository/restaurantInfo";
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager";

export default {
  name: "IndexPage",
  components: {UserCodePage, HotpotOpenTablePage},
  data: function () {
    return {
      showHotPotOpenTablePage: false,
      loggedIn: false,
      currentOrderInfo: null,
      showUserCode: false,

      GlobalConfig,
      imageList: [],
    };
  },
  computed: {
    ...mapState(['isLogin', 'userInfo']),
    tableUsing() {
      return this.currentOrderInfo?.usageStatus === '1'
    }
  },
  async mounted() {
    this.currentOrderInfo = (await getTableInfo())
    this.imageList = await getBannerImageList()
  },
  destroyed() {
    clearInterval(this.handel)
  },
  methods: {
    displayComingPage() {
      this.$store.commit('showComingPage', true)
    },
    startLogin() {
      this.$store.commit('showLoginPage', true)
    },
    async openTable() {
      const res = await openTableWithScanQR((await getTableInfo()).id)
      console.log(res)
      if (res.status === 'good') {
        await this.$router.push('/menu')
      }
    }
  }
}
</script>

<style scoped>

</style>
