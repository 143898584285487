import {loadBaseConfig} from 'aaden-base-model/lib/Models/GlobalSettings'
import hillo from 'hillo'
import IKUtils from 'innerken-js-utils'
import {LocalSettingManager} from "biewangle";
import {showRecords} from "@/plugins/fire-base";

const defaultConfig = require('@/assets/config.json')
let GlobalConfig = Object.assign({locale: 'de'}, defaultConfig)
window.Config = GlobalConfig

export async function loadConfig() {
  GlobalConfig = Object.assign(GlobalConfig, await loadBaseConfig(defaultConfig))
  const shortName = location.hostname.split('.')[0]
  console.log(shortName)
  const record = (await showRecords()).find(s => s.subdomainName === shortName)
  console.log(record)
  let deviceId = localStorage.getItem('deviceId') ?? GlobalConfig.DeviceId
  if (record) {
    GlobalConfig.DeviceId = deviceId
    deviceId = record.deviceId
  }

  // 判断getQueryString中是否有deviceId或DeviceId
  GlobalConfig.Base = getNgrokBaseUrlFromDeviceId(deviceId)
  console.log(GlobalConfig.Base, "GlobalConfig.Base")
  hillo.initial(GlobalConfig.Base + '/PHP/')
  GlobalConfig.imageRoot = getImgRoot(GlobalConfig.Base)
  GlobalConfig.resourceRoot = GlobalConfig.Base + '/Resource/'
}

function getNgrokBaseUrlFromDeviceId(deviceId) {
  return `http://IK${deviceId.toString().padStart(4, '0')}.ngrok.aaden.io`
}

export function getImgRoot(base) {
  return base + GlobalConfig.imgRoot
}

export const TableName = IKUtils.getQueryString("tableName") || IKUtils.getQueryString("TableName")
console.log(TableName, 'Table Name')

export const Remember = LocalSettingManager.config({requestLocale: "de", locale: "de"})

export default GlobalConfig


