<template>
  <div style="width: 100%" class="fill-height">
    <div class="fill-height d-flex flex-column align-center justify-center">
      <div style="width: 100%;max-width: 300px;" class="d-flex justify-center">
        <lottie-animation loop :animation-data="require('@/assets/rocket.json')"></lottie-animation>
      </div>
      <div class="pa-6 mt-4" style="width: 100%">
        <div class="text-h4 decoText font-weight-bold">{{ $t('DescNumber') }} {{ tableName }}</div>
        <div class="text-body-1 mt-4">{{ $t('OrderingExperience') }}</div>
        <v-btn color="primary black--text" class="mt-4" x-large elevation="0"
               @click="openTable" block>
          <v-icon left>mdi-run-fast</v-icon>
          {{ $t('OrderNow') }}
        </v-btn>
      </div>
    </div>
    <v-bottom-sheet scrollable fullscreen v-model="showHotPotOpenTablePage" persistent>
      <v-card tile color="black">
        <hotpot-open-table-page
          @close="showHotPotOpenTablePage=false"
          @confirm="openTable"
        />
      </v-card>
    </v-bottom-sheet>

  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'
import {getCategoryWithDishes, getTableInfo, getTableName, openTableWithScanQR} from "@/dataLayer/service/api"
import HotpotOpenTablePage from "@/pages/HotpotOpenTablePage.vue"; // import lottie-web-vue


export default {
  components: {HotpotOpenTablePage, LottieAnimation},
  name: "LoadingPage",
  data: function () {
    return {
      tableName: getTableName(),
      showHotPotOpenTablePage: false
    }
  },
  methods: {
    async openTable() {
      const res = await openTableWithScanQR((await getTableInfo()).id)
      console.log(res)
      if (res.status === 'good') {
        await this.$router.push('/')
        await this.$router.push('/menu')
        this.showHotPotOpenTablePage = false
      }
    }
  },
  async mounted() {
    await getCategoryWithDishes('DE')
    const tableStatus = await getTableInfo()
    console.log(tableStatus)
    if (tableStatus.usageStatus === '1') {
      await this.$router.push('/menu')
    }
  }
}
</script>

<style scoped>

</style>
